import React, { useState, useRef, useEffect } from "react";
// import SmoothScrollbar from 'smooth-scrollbar';
import { useLocation } from "react-router-dom";
import Navbar from "../Component/Navbar";
import Footer from "../Component/Footer";
import CookieBar from "../Component/CookieBar";
const LazyApp = React.lazy(() => import("../App"));

function Layout() {
  const [activePlanIndex, setActivePlanIndex] = useState(null);
  const [customAmount, setCustomAmount] = useState(0);
  const [bundlesId, setBundlesId] = useState(null);
  const [currencySymbol, setCurrencySymbol] = useState("$");
  const [dynamicUrl, setDynamicUrl] = useState("/");

  const bundleSectionRef = useRef(null);
  const [scrollPosition, setScrollPosition] = useState(0);


  const handleScroll = () => {
    setScrollPosition(window.scrollY);
  };

  useEffect(() => {
    window.addEventListener("scroll", handleScroll);

    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);
  const navbarStyle = {
    backgroundColor:
      scrollPosition > 18 ? "#ffffffeb" : "rgba(255, 255, 255, 0)",
  };

  const getUserLocation = async () => {
    try {
      // const position = await getCurrentPosition();
      // const { latitude, longitude } = position.coords;
      // const locationResponse = await fetch("https://ipapi.co/json/");
      const response = await fetch('https://geolocation-db.com/json/');
      const data = await response.json();
      const ipv4 = data?.IPv4;
      const locationResponse = await fetch(`https://ipapi.co/${ipv4}/json/`);
      const locationResponseData = await locationResponse.json();
      // console.log("podition", locationResponseData.country_code);
      // const latitude = 54.5336455;
      // const longitude = -1.8214205;
      // const response = await fetch(
      //   `https://api.bigdatacloud.net/data/reverse-geocode-client?latitude=${latitude}&longitude=${longitude}&localityLanguage=en`
      // );
      // if (!response.ok) {
      //   throw new Error("Failed to fetch country data");
      // }
      // const data = await response.json();

      const country = locationResponseData.country_code;
      console.log({country});

      updateCurrencySymbol(country);
    } catch (error) {
      console.error("Error fetching country:", error);
      setDynamicUrl("/");
      setCurrencySymbol("$");
    }
  };

  // const getCurrentPosition = () => {
  //   return new Promise((resolve, reject) => {
  //     navigator.geolocation.getCurrentPosition(resolve, reject);
  //   });
  // };

  const updateCurrencySymbol = (country) => {
    switch (country) {
      case "US":
        setCurrencySymbol("$");
        break;
      case "UK":
        setCurrencySymbol("£");
        setDynamicUrl("/uk/");
        break;

      case "GB":
        setCurrencySymbol("£");
        setDynamicUrl("/uk/");
        break;
      // case "IN":
      //   setCurrencySymbol("₹");
      //   break;
      // case "EU":
      //   setCurrencySymbol("€");
      //   break;
      // case "AU":
      //   setCurrencySymbol("A$");
      //   break;
      // case "CA":
      //   setCurrencySymbol("CA$");
      //   break;
      // case "JP":
      //   setCurrencySymbol("¥");
      //   break;
      // case "KR":
      //   setCurrencySymbol("₩");
      //   break;
      // case "MX":
      //   setCurrencySymbol("Mex$");
      //   break;
      // case "RU":
      //   setCurrencySymbol("₽");
      //   break;
      default:
        setCurrencySymbol("$");
        break;
    }
  };

  useEffect(() => {
    getUserLocation();
  }, []);

  const scrollToBundles = () => {
    if (bundlesId) {
      const bundlesSection = document.getElementById(bundlesId);
      bundlesSection.scrollIntoView({ behavior: "smooth" });
    }
  };

  const location = useLocation();
  const isPaymentPage =
    location.pathname === "/checkout" ||
    location.pathname.endsWith("/uk/checkout");
  const isPaymentArmyPage =
    location.pathname === "/armycov" ||
    location.pathname.endsWith("uk/armycov");
  return (
    <div className="scroll-content relative h-[100vh] w-[100%]">
      {!isPaymentPage && !isPaymentArmyPage && (
        <div id="navbarContainer" style={{ ...navbarStyle, transition: "0.4s" }} className="flex justify-center w-[100%] z-[999]  fixed top-[0%]">
          <Navbar dynamicUrl={dynamicUrl} currencySymbol={currencySymbol} />
        </div>
      )}


      <LazyApp
        setActivePlanIndex={setActivePlanIndex}
        activePlanIndex={activePlanIndex}
        setBundlesId={setBundlesId}
        setCustomAmount={setCustomAmount}
        customAmount={customAmount}
        bundleSectionRef={bundleSectionRef}
        currencySymbol={currencySymbol}
        dynamicUrl={dynamicUrl}
      />

      {!isPaymentPage && !isPaymentArmyPage && (
        <Footer
          activePlanIndex={activePlanIndex}
          scrollToBundles={scrollToBundles}
          bundleSectionRef={bundleSectionRef}
          currencySymbol={currencySymbol}
          dynamicUrl={dynamicUrl}
        />
      )}
      <CookieBar />
    </div>
  );
}

export default Layout;
